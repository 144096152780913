html,
body {
    margin: 0;
    padding: 0;
    font-family: Arial;
    font-size: 14px;
    background: initial;
    color: initial;
    max-width: initial;
}

/* Reset Sakura spacing that might interfere with our layout */
body, main {
    padding: 0;
    margin: 0;
}

.hint {
    display: flex;
    align-items: center;
    gap: 6px;

    margin-bottom: 6px;

    svg {
        width: 17px;
        height: 17px;

        path {
            fill: #fff;
        }
    }
}

canvas {
    position: fixed;
    top: 0;
    left: 0;
    cursor: none;

    &.filtered {
        filter: blur(9px) brightness(4) contrast(200);
        background: rgba(255, 255, 255, 0.3);
    }
}

.controls {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
    color: #fff;

    a {
        color: #fff;
        text-decoration: underline;
    }

    label {
        display: block;
        margin-bottom: 5px;
    }

    .status {
        margin-top: 10px;
    }
}

.cursor-circle {
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #74747467;
    will-change: transform;
    position: fixed;
    z-index: 100;
    pointer-events: none;
    transform: translate(-50%, -50%);
}

.info {
    display: none;
    position: fixed;
    will-change: transform;
    z-index: 100;
    pointer-events: none;
    margin-left: 70px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
    color: white;
    transform: translate(-50%, -50%);
}

input {
    margin-bottom: 0;
    padding: 0;
}